.main-container {
  height: -webkit-calc(100vh - 170px);
  max-width: 1500px;
}

.absolute-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.mapbox-segments {
  position: absolute;
  top: 0;
  bottom: 0;
}

h3 {
  margin: 4px;
}

.sidebar {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-left: 2px solid #222529;
  border-right: none;
  border-top: 2px solid #222529;
  border-bottom: 2px solid #222529;
}

.sidebar-heading {
  background-color: #02b8d8;
  border-bottom: 2px solid #222529;
  color: #FFFFFF;
  padding-left: 12px;
  padding-right: none;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
}

.sidebar-filter {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.sidebar-dropdown-button {
  border: 1px solid rgba(0,0,0,.15);
  width: 100%;
}

.sidebar-listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.sidebar-listings .sidebar-listings-item {
  border-bottom: 1px solid #eee;
  padding: 10px;
  font-size: 15px;
}

.sidebar-listings .sidebar-listings-item:first-child {
  border-top: 1px solid #eee;
}

.sidebar-listings .sidebar-listings-item .title small {
  font-weight: 400;
}

.sidebar-listings .sidebar-listings-item.active .title,
.sidebar-listings .sidebar-listings-item .title:hover {
  color: #0090B1;
}

.sidebar-listings .sidebar-listings-item.active {
  background-color: #f8f8f8;
}

.mapboxgl-map {
  border-left: 2px solid #222529;
  border-right: 2px solid #222529;
  border-top: 2px solid #222529;
  border-bottom: 2px solid #222529;
}

.mapboxgl-popup {
  padding-bottom: 40px;
}

.mapboxgl-popup-content {
  padding: 0px;
  border: 2px solid #222529;
  margin-bottom: 0px;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 10px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #02b8d8;
}

.mapbox-popup {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.mapbox-popup-header {
  background: #02b8d8;
  color: #fff;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}

.mapbox-popup-content {
  padding: 10px;
  font-size: 13px;
  font-style: normal;
  width: 100%;
}

.mapbox-popup-button {
  border: 1px solid rgba(0,0,0,.15);
  font-size: 13px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 375px;
}

.mapbox-popup-button:focus, .mapbox-popup-button:active:focus, .mapbox-popup-button.active:focus {
    outline: 1px solid rgba(0,0,0,.15);
    box-shadow: none;
}

.marker {
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background-image: url('marker.png');
}

.sidebar-listings .sidebar-listings-item .title {
  display: block;
  color: #00202e;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
}
